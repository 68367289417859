import React, { ReactNode } from 'react'
import { toast, ToastContent, ToastOptions } from 'react-toastify'
import { Typography } from '@med-io/neural-ui'

export const notify = <TData = unknown,>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData>,
) => {
  toast(
    <Typography size='sm' weight='semiBold'>
      {content as ReactNode}
    </Typography>,
    options,
  )
}
