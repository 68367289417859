import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistCombineReducers,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { authApi } from './authApi/authApi'
import { authSlice } from './authSlice/authSlice'
import { rtkQueryErrorLogger } from './errorHandler'
import { submissionApi } from 'src/redux/submissionApi/submissionApi'

const persistConfig = {
  key: 'root',
  whitelist: ['auth'],
  storage,
}

const rootReducer = {
  [authApi.reducerPath]: authApi.reducer,
  [submissionApi.reducerPath]: submissionApi.reducer,
  auth: authSlice.reducer,
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([authApi.middleware, submissionApi.middleware, rtkQueryErrorLogger]),
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
