import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQuery } from '../common'
import {
  CreateSubmissionRequest,
  CreateSubmissionResponse,
  ListSubmissionSpeechesResponse,
  ListSubmissionsResponse,
} from 'src/redux/submissionApi/submissionApi.types'

export const submissionApi = createApi({
  baseQuery: baseQuery(),
  reducerPath: 'submissionApi',
  tagTypes: ['Submission'],
  endpoints: (builder) => ({
    createSubmission: builder.mutation<CreateSubmissionResponse, CreateSubmissionRequest>({
      query: (payload) => ({
        url: '/submission',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Submission'],
    }),
    listSubmissions: builder.query<ListSubmissionsResponse, void>({
      query: () => '/submission',
      providesTags: ['Submission'],
    }),
    listSubmissionSpeeches: builder.query<ListSubmissionSpeechesResponse, string>({
      query: (payload) => `/submission/${payload}/speeches`,
      providesTags: ['Submission'],
    }),
  }),
})

// Export hooks and types for usage in components
export const {
  useCreateSubmissionMutation,
  useListSubmissionsQuery,
  useListSubmissionSpeechesQuery,
} = submissionApi
