import React from 'react'
import styled from 'styled-components'
import { Typography } from '@med-io/neural-ui'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(21, 113, 212);
`

const TypographyStyled = styled(Typography)`
  color: white !important;
`

const Intro = React.memo(() => {
  return (
    <Wrapper>
      <TypographyStyled type='display' weight='regular' size='xl'>
        Podcastis
      </TypographyStyled>
    </Wrapper>
  )
})

export default Intro
