import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Badge, Box, Button, FormikTextArea, icons, Typography } from '@med-io/neural-ui'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { ModelSelect } from 'src/common/selects/ModelSelect'
import {
  useCreateSubmissionMutation,
  useListSubmissionsQuery,
} from 'src/redux/submissionApi/submissionApi'

const { ArrowCircleBrokenRight } = icons

const validationSchema = Yup.object().shape({
  content: Yup.string().required('Required'),
  model: Yup.string().required('Required'),
})

export const SubmissionForm = () => {
  const history = useHistory()
  const [rows, setRows] = useState(0)
  const [fileContent, setFileContent] = useState('')
  const { id } = useParams<{ id: string | undefined }>()

  const [createSubmission] = useCreateSubmissionMutation()

  const {
    data,
    requestId: submissionsRequestId,
    isSuccess: isSubmissionsSuccess,
  } = useListSubmissionsQuery()

  const submission = data?.items.find((item) => item.id === id)

  // read file content by file_url in submission
  useEffect(() => {
    if (submission) {
      fetch(submission.file_url)
        .then((r) => r.text())
        .then((t) => setFileContent(t))
    }
  }, [submission, id])

  const initialValues = {
    content: fileContent || '',
    model: submission?.model || '',
  }

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const data = await createSubmission({
        content: values.content,
        model: values.model,
      }).unwrap()
      history.push(`/submission/${data.id}`)
    } catch (error) {
      console.error(error)
    }
  }

  const isExisting = !!id

  // count number of rows for TextArea to fill the screen and change on resize
  // let rows = Math.floor(window.innerHeight / 27)

  useLayoutEffect(() => {
    const updateRows = () => {
      const windowHeight = window.innerHeight
      const buttonHeight = 52 // Approximate height of the button, adjust as needed
      const otherUIHeight = 56 // Adjust for other padding/margins, adjust as needed
      const availableHeight = windowHeight - buttonHeight - otherUIHeight

      const lineHeight = 24 // Adjust this to match the actual line height of the textarea
      setRows(Math.floor(availableHeight / lineHeight))
    }

    updateRows()
    window.addEventListener('resize', updateRows)
    return () => window.removeEventListener('resize', updateRows)
  }, [])

  return (
    <Box flex={1}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, isSubmitting }) => (
          <Form
            style={{
              width: '100%',
            }}
          >
            <Box display={isExisting ? 'block' : 'flex'} justifyContent='space-between' gap={1}>
              <Box flex={1}>
                {values.model && isExisting ? (
                  <Box mb={1}>
                    <Badge variant='filled' size='lg' color='primary'>
                      {values.model}
                    </Badge>
                  </Box>
                ) : null}
                <FormikTextArea
                  readOnly={isExisting || isSubmitting}
                  fullWidth
                  size='md'
                  maxLength={1500}
                  name='content'
                  placeholder='Enter the text'
                  rows={rows}
                />
                <Typography color='gray500' mt={1} size='sm'>
                  {values.content.length} / 1500
                </Typography>
              </Box>
              {isExisting ? null : (
                <Box flex={1}>
                  <ModelSelect name='model' size='xl' disabled={isSubmitting} />
                  <Button
                    loading={isSubmitting}
                    type='submit'
                    rightIcon={ArrowCircleBrokenRight}
                    fullWidth
                    size='xxl'
                    variant='primary'
                  >
                    Podcast
                  </Button>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
