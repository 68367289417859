import React from 'react'
import { Box } from '@med-io/neural-ui'
import { SpeechList } from 'src/screens/home/Podcast/SpeechList'

export const Podcast = () => {
  return (
    <Box
      style={{
        flex: 1,
        height: '100%',
      }}
    >
      <SpeechList />
    </Box>
  )
}
