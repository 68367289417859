import React, { useCallback, useRef, useState } from 'react'
import { Speech } from 'src/screens/home/Podcast/Speech'
import { useParams } from 'react-router-dom'
import { useListSubmissionSpeechesQuery } from 'src/redux/submissionApi/submissionApi'
import { Box } from '@med-io/neural-ui'
import { Controls } from 'src/screens/home/Podcast/Controls/Controls'
import AutoSizer from 'react-virtualized-auto-sizer'

export const SpeechList = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useListSubmissionSpeechesQuery(id)
  const listRef = useRef(null)
  const [playIndex, setPlayIndex] = useState(-1)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isBrowserPlaying, setIsBrowserPlaying] = useState(false)
  const [browserPlayIndex, setBrowserPlayIndex] = useState(-1)

  const handlePlay = () => {
    if (playIndex === -1) {
      setPlayIndex(0)
    }
    setIsPlaying(true)
  }

  const handlePause = () => {
    setIsPlaying(false)
  }

  const handleBrowserPause = () => {
    setIsBrowserPlaying(false)
  }

  const onFinishPlaying = useCallback(
    (index: number) => {
      if (!data) {
        return
      }
      if (index === data.items.length - 1) {
        setPlayIndex(-1)
        setIsPlaying(false)
      } else {
        setPlayIndex(index + 1)
      }
    },
    [data],
  )

  const handleBrowserPlay = () => {
    if (browserPlayIndex === -1) {
      setBrowserPlayIndex(0)
    }
    setIsBrowserPlaying(true)
  }

  const onBrowserFinishPlaying = useCallback(
    (index: number) => {
      if (!data) {
        return
      }
      if (index === data.items.length - 1) {
        setBrowserPlayIndex(-1)
        setIsBrowserPlaying(false)
      } else {
        setBrowserPlayIndex(index + 1)
      }
    },
    [data],
  )

  if (!data) {
    return null
  }

  return (
    <AutoSizer
      style={{
        backgroundColor: 'red',
      }}
    >
      {({ height, width }: { height: number; width: number }) => (
        <Box h={`${height}px`} w={`${width}px`}>
          <Box overflowY='auto' h={`${height - 100}px`} w='100%' pb={1}>
            {data.items.map((speech, index) => (
              <Speech
                {...speech}
                key={speech.text}
                parentWidth={500}
                isPlaying={isPlaying && playIndex === index}
                isBrowserPlaying={isBrowserPlaying && browserPlayIndex === index}
                onFinishPlaying={() => onFinishPlaying(index)}
                onBrowserFinishPlaying={() => onBrowserFinishPlaying(index)}
                align={index % 2 === 0 ? 'left' : 'right'}
              />
            ))}
          </Box>
          <Controls
            isBrowserPlaying={isBrowserPlaying}
            isPlaying={isPlaying}
            onPlay={handlePlay}
            onPause={handlePause}
            onBrowserPlay={handleBrowserPlay}
            onBrowserPause={handleBrowserPause}
          />
        </Box>
      )}
    </AutoSizer>
  )
}
