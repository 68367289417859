import React from 'react'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
// import Button from "src/components/Button";
import {
  Button,
  FormikEmailInput,
  FormikInput,
  FormikPasswordInput,
  icons,
} from '@med-io/neural-ui'
import { useLoginMutation, useSignUpMutation } from 'src/redux/authApi/authApi'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser } from 'src/redux/authSlice/authSlice'

const { PasscodeLock, Passcode, User01 } = icons

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 50px;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
`

const HorizontalBox: any = styled.div`
  position: relative;
  padding: 1rem 0;
  flex-shrink: 1;

  &.flex {
    display: flex;

    & > * {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.barcode {
    svg {
      width: 100% !important;
    }
  }
`

const Title = styled.div`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
`

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: #9c9c9c;
`

const FormWrapper = styled.div`
  width: 100%;

  & > * {
    margin: 2rem 0;
  }

  Button {
    width: 100%;
  }
`

const LoginForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  //   check route. if route is login
  const { pathname } = useLocation()

  const isLogin = pathname === '/login'

  const [login] = useLoginMutation()
  const [signUp] = useSignUpMutation()

  const onSubmit = async (values: any) => {
    try {
      if (isLogin) {
        const data = await login(values).unwrap()
        dispatch(setUser(data))
        history.push('/')
      } else {
        const data = await signUp(values).unwrap()
        history.push('/login')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const schema = Yup.object().shape({
    name: !isLogin ? Yup.string().max(50).required('Required') : Yup.string(),
    email: Yup.string()
      .email('Wrong email format')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Wrong email format',
      )
      .required('Required'),
    password: Yup.string()
      .min(8, 'Not less than 8 characters')
      .max(16, 'No more than 16 characters')
      .required('Required'),
  })

  return (
    <Wrapper>
      <Title>{isLogin ? 'Sign in' : 'Create account'}</Title>
      {/*<Subtitle>Введите ваши данные для входа в дэшбоард.</Subtitle>*/}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }: any) => {
          return (
            <FormWrapper>
              <Form>
                {!isLogin && (
                  <FormikInput
                    leftIcon={User01}
                    disabled={isSubmitting}
                    size='md'
                    name='name'
                    label='Name'
                    placeholder='Enter your name'
                    fullWidth
                  />
                )}
                <FormikEmailInput
                  disabled={isSubmitting}
                  size='md'
                  name='email'
                  label='Email'
                  placeholder='Enter your email'
                  fullWidth
                />
                <FormikPasswordInput
                  disabled={isSubmitting}
                  leftIcon={Passcode}
                  size='md'
                  name='password'
                  label='Password'
                  placeholder='Enter your password'
                  fullWidth
                />
                <Button
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  mt={1}
                  type='submit'
                  size='md'
                  variant='primary'
                >
                  {isLogin ? 'Sign in' : 'Create account'}
                </Button>
              </Form>
            </FormWrapper>
          )
        }}
      </Formik>
      <Link to={isLogin ? '/sign-up' : '/login'}>
        <Button mt={1} size='md' variant='tertiaryColor' fullWidth>
          {isLogin ? 'Create account' : 'Sign in'}
        </Button>
      </Link>
    </Wrapper>
  )
}

export default LoginForm
