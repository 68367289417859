import React from 'react'
import { Provider } from 'react-redux'
import Default from 'src/Default' // import { store } from 'src/redux/store'
import { Router } from 'react-router-dom'
import 'src/styles/global.css'
import 'react-toastify/dist/ReactToastify.css'
import { createBrowserHistory } from 'history'
import { persistor, store } from 'src/redux/store'
import defaultTheme from 'src/styles/defaultTheme.json'
import { ThemeProvider as RootThemeProvider } from 'styled-components'
import { ThemeProvider } from '@med-io/neural-ui'
import { PersistGate } from 'redux-persist/integration/react'

export const history = createBrowserHistory()

function App() {
  return (
    <Router history={history}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootThemeProvider theme={defaultTheme}>
            <ThemeProvider>
              <Default />
            </ThemeProvider>
          </RootThemeProvider>
        </PersistGate>
      </Provider>
    </Router>
  )
}

export default App
