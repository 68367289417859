import React from 'react'
import { Route, Switch } from 'react-router-dom'
import MainRoutes from 'src/routes/MainRoutes'
import PrivateRoute from 'src/routes/ProtectedRoute'
import NotFound from 'src/screens/NotFound'
import { LoginScreen } from 'src/screens/login'

const DefaultRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path='/login' component={LoginScreen} />
      <Route exact path='/sign-up' component={LoginScreen} />
      <PrivateRoute path='/' component={MainRoutes} />
      <Route path='*' component={NotFound} />
    </Switch>
  )
}

export default DefaultRoutes
