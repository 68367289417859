import React from 'react'
import LoginForm from 'src/screens/login/LoginForm'
import LoginLayout from 'src/layouts/LoginLayout'

export const LoginScreen = () => {
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  )
}
