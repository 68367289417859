import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginResponse, User } from 'src/redux/authApi/authApi.types'

export type AuthSliceState = {
  user: User | null
  token: string | null
}

const initialState: AuthSliceState = {
  user: null,
  token: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<LoginResponse>) {
      state.user = action.payload.user
      state.token = action.payload.token
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    clearUser(state) {
      state.user = null
    },
    clearToken(state) {
      state.token = null
    },
    clearAuth(state) {
      state.user = null
      state.token = null
    },
  },
})

export const { setUser, setToken, clearUser, clearToken, clearAuth } = authSlice.actions
