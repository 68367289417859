import React from 'react'
import './VoiceAssistantIcon.css'

const VoiceAssistantIcon = () => {
  return (
    <div className='voice-assistant-icon'>
      <div className='circle'></div>
      <div className='circle'></div>
      <div className='circle'></div>
    </div>
  )
}

export default VoiceAssistantIcon
