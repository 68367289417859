import React from 'react'
import Fuse from 'fuse.js'
import { FormikSelect, FormikSelectProps } from '@med-io/neural-ui'

const models = [
  {
    value: 'llama-3.1-70b-versatile',
    label: 'llama-3.1-70b-versatile',
  },
  {
    value: 'llama-3.1-8b-instant',
    label: 'llama-3.1-8b-instant',
  },
  {
    value: 'llama3-groq-70b-8192-tool-use-preview',
    label: 'llama3-groq-70b-8192-tool-use-preview',
  },
  {
    value: 'llama3-groq-8b-8192-tool-use-preview',
    label: 'llama3-groq-8b-8192-tool-use-preview',
  },
  {
    value: 'llama-guard-3-8b',
    label: 'llama-guard-3-8b',
  },
  {
    value: 'llama3-70b-8192',
    label: 'llama3-70b-8192',
  },
  {
    value: 'llama3-8b-8192',
    label: 'llama3-8b-8192',
  },
  {
    value: 'mixtral-8x7b-32768',
    label: 'mixtral-8x7b-32768',
  },
  {
    value: 'gemma-7b-it',
    label: 'gemma-7b-it',
  },
  {
    value: 'gemma2-9b-it',
    label: 'gemma2-9b-it',
  },
  {
    value: 'whisper-large-v3',
    label: 'whisper-large-v3',
  },
]

type CountrySelectProps = Omit<
  {
    name: string
  } & FormikSelectProps<{
    value: string
    label: string
  }>,
  | 'options'
  | 'getOptionValue'
  | 'getOptionLabel'
  | 'searchOptions'
  | 'delayedSearch'
  | 'searchable'
  | 'label'
>

export const ModelSelect = ({ size = 'sm', ...props }: CountrySelectProps) => {
  const searchOptions: Fuse.IFuseOptions<{
    value: string
    label: string
  }> = {
    keys: [{ name: 'label', weight: 1 }],
  }

  return (
    <FormikSelect
      options={models}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.label}
      searchOptions={searchOptions}
      delayedSearch={150}
      fullWidth
      searchable
      placeholder='Choose model'
      size={size}
      {...props}
    />
  )
}
