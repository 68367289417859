import React, { useMemo } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import SidebarLayout from 'src/layouts/SidebarLayout'
import { SideMenuItem } from 'src/components/Menus/SideMenu'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { HomeScreen } from 'src/screens/home/HomeScreen'
import SideMenuUserBlock from 'src/common/SideMenuUserBlock'
import { icons } from '@med-io/neural-ui'
import { useListSubmissionsQuery } from 'src/redux/submissionApi/submissionApi'
import { SettingsScreen } from 'src/screens/Settings'

const { Home05, Settings01 } = icons

const MainRoutes = React.memo(() => {
  const location = useLocation()
  const { data, requestId, isSuccess } = useListSubmissionsQuery()

  const menuItems = useMemo(() => {
    if (isSuccess && data) {
      return data.items.map((submission) => (
        <SideMenuItem
          key={submission.id}
          title={submission.title}
          to={`/submission/${submission.id}`}
        />
      ))
    } else {
      return []
    }
  }, [data])

  return (
    <SidebarLayout
      backLink='/'
      mainHeader
      footer={<SideMenuUserBlock />}
      menuItems={[
        <SideMenuItem key='Home' title='Home' to='/' exact={true} icon={Home05} />,
        <SideMenuItem
          key='Settings'
          title='Settings'
          to='/settings'
          exact={true}
          icon={Settings01}
        />,
        ...menuItems,
      ]}
    >
      <TransitionGroup style={{ height: '100%' }}>
        {/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          */}
        <CSSTransition
          style={{ height: '100%' }}
          key={location.pathname}
          classNames='fade'
          timeout={300}
        >
          <Switch location={location}>
            <Route exact path='/' component={HomeScreen} />
            <Route path='/settings' component={SettingsScreen} />
            <Route path='/submission/:id' component={HomeScreen} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </SidebarLayout>
  )
})

export default MainRoutes
