import React from 'react'
import { ToastContainer } from 'react-toastify'
import { useTheme } from 'styled-components'
import DefaultRoutes from 'src/routes'

function Default() {
  const theme = useTheme()
  console.log(theme)
  return (
    <>
      <ToastContainer autoClose={5000} hideProgressBar closeButton={true} position='bottom-right' />
      <DefaultRoutes />
    </>
  )
}

export default Default
