import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'

import { notify } from '../components/notify/notify'

import { authApi } from './authApi/authApi'
import { clearAuth } from './authSlice/authSlice'

// Define a custom type for the action payload
interface RejectedActionPayload {
  status: number
  data?: {
    error?: string | { message: string }[]
    message?: string | { error: string }[]
  }
}

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const payload = action.payload as RejectedActionPayload

    if (payload.status === 401) {
      api.dispatch(clearAuth())
      api.dispatch(authApi.util.resetApiState())
      // api.dispatch(vehicleApi.util.resetApiState());
      // api.dispatch(requestApi.util.resetApiState());
      // api.dispatch(paymentApi.util.resetApiState());
    } else if (payload.status !== 404) {
      if (payload.data?.error) {
        if (typeof payload.data.message === 'string') {
          notify(payload.data.message)
        } else if (typeof payload.data.message?.[0].error === 'string') {
          notify(payload.data.message[0].error)
        }
      } else {
        // toast.show('Unknown error');
      }
      // Send error to Sentry
      // Sentry.captureException(payload.data?.error);
    }
  }

  return next(action)
}
