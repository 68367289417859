import React, { useEffect, useRef } from 'react'
import { Box, Typography } from '@med-io/neural-ui'
import styled from 'styled-components'
import VoiceAssistantIcon from 'src/screens/home/Podcast/VoiceAssistantIcon'
import { SubmissionSpeech } from 'src/redux/submissionApi/submissionApi.types'

export type SpeechProps = SubmissionSpeech & {
  align: 'left' | 'right'
  parentWidth: number
  isPlaying: boolean
  onFinishPlaying: () => void
  isBrowserPlaying: boolean
  onBrowserFinishPlaying: () => void
}

const SpeechWrapper = styled.div<{
  align?: 'left' | 'right'
}>`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.align === 'left' ? 'flex-start' : 'flex-end')};
  margin-left: ${(props) => (props.align === 'right' ? '1%' : '0')};
  margin-right: ${(props) => (props.align === 'left' ? '1%' : '0')};
  background-color: ${(props) =>
    props.align === 'left'
      ? props.theme.colors.palette.teal200
      : props.theme.colors.palette.cyan200};
  border-radius: ${(props) =>
    props.align === 'left' ? '15px 15px 15px 0px' : '15px 15px 0px 15px'};
  padding: 10px;
`

export const Speech = ({
  align,
  text,
  audio_file_url,
  isPlaying,
  onFinishPlaying,
  role,
  isBrowserPlaying,
  onBrowserFinishPlaying,
}: SpeechProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    const audioElement = audioRef.current

    if (audioElement) {
      if (isPlaying) {
        audioElement.play()
      } else {
        audioElement.pause()
      }
    }

    const handleEnded = () => {
      onFinishPlaying()
    }

    if (audioElement) {
      audioElement.addEventListener('ended', handleEnded)
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handleEnded)
      }
    }
  }, [isPlaying, onFinishPlaying])

  useEffect(() => {
    if (isBrowserPlaying) {
      const utterance = new SpeechSynthesisUtterance(text)
      utterance.onend = () => {
        onBrowserFinishPlaying()
      }
      window.speechSynthesis.speak(utterance)
    }
  }, [isBrowserPlaying, onBrowserFinishPlaying, text])

  return (
    <Box
      display='flex'
      alignItems='flex-start'
      justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
    >
      {align === 'right' && isPlaying && (
        <Box mt='5px'>
          <VoiceAssistantIcon />
        </Box>
      )}
      <SpeechWrapper align={align}>
        <Typography component='div' color='gray500' mb={0.5} weight='semiBold'>
          {role}
        </Typography>
        <Typography component='div' color='gray700'>
          {text}
        </Typography>
      </SpeechWrapper>
      {align === 'left' && isPlaying && (
        <Box mt='5px'>
          <VoiceAssistantIcon />
        </Box>
      )}
      <audio ref={audioRef} src={audio_file_url} />
    </Box>
  )
}
