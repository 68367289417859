import { createApi } from '@reduxjs/toolkit/query/react'
import { LoginRequest, LoginResponse, User } from 'src/redux/authApi/authApi.types'

import { baseQuery } from '../common'

export const authApi = createApi({
  baseQuery: baseQuery(),
  reducerPath: 'authApi',
  tagTypes: ['User'],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (payload) => ({
        url: '/sign-in',
        method: 'POST',
        body: payload,
      }),
    }),
    signUp: builder.mutation<LoginResponse, LoginRequest>({
      query: (payload) => ({
        url: '/sign-up',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
})

// Export hooks and types for usage in components
export const { useLoginMutation, useSignUpMutation } = authApi
