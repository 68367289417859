import React from 'react'
import { Box, layouts } from '@med-io/neural-ui' // eslint-disable-next-line import/no-unresolved
import { SubmissionForm } from 'src/screens/home/SubmissionForm'
import { Podcast } from 'src/screens/home/Podcast/Podcast'
import { useParams } from 'react-router-dom'

const { TopBarLayout } = layouts

export const HomeScreen = () => {
  const { id } = useParams<{ id: string | undefined }>()

  const isNew = !id
  return (
    <TopBarLayout hideTopBar>
      <Box display='flex' gap={2} justifyContent='space-between' h='100%'>
        <SubmissionForm />
        {isNew ? null : <Podcast />}
      </Box>
    </TopBarLayout>
  )
}
