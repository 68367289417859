import styled, { useTheme } from 'styled-components'
import { Theme } from 'src/styles/theme'
import { Box } from '@med-io/neural-ui'

const Wrapper = styled(Box)`
  &:hover {
    background-color: ${(p) => p.theme.colors.palette.gray50};
  }
`

const SideMenuUserBlock = ({ expanded }: { expanded?: boolean }) => {
  const theme: Theme = useTheme() as Theme
  return null
  // return (
  //   <Wrapper title={`${name} ${surname} ${patronymic}`} width='100%' padding='12px 16px' alignItems='center'>
  //     <Avatar name={name} surname={surname} src={avatar} size={2} />
  //     {expanded && <Box display='block' padding='0 16px'>
  //       <Typography component='div' customFontSize={0.7} color='gray600'>{name} {surname}</Typography>
  //       <Typography component='div' customFontSize={0.6} color='gray400'>{email}</Typography>
  //     </Box>}
  //   </Wrapper>
  // );
}

export default SideMenuUserBlock
