import React from 'react'
import { Box, Button, IconButton, icons } from '@med-io/neural-ui'
import styled from 'styled-components'

const { Play, PauseCircle } = icons

const Wrapper = styled(Box)`
  border-top: ${(props) => `1px solid ${props.theme.colors.palette.gray200}`};
`

type ControlsProps = {
  isPlaying: boolean
  isBrowserPlaying: boolean
  onPlay: () => void
  onPause: () => void
  onBrowserPlay: () => void
  onBrowserPause: () => void
}

export const Controls = ({
  onPlay,
  isPlaying,
  onPause,
  onBrowserPlay,
  onBrowserPause,
  isBrowserPlaying,
}: ControlsProps) => {
  return (
    <Wrapper w='100%' display='flex' alignItems='center' justifyContent='center' pt={1} gap={3}>
      <Button
        size='md'
        variant='secondaryColor'
        onClick={isBrowserPlaying ? onBrowserPause : onBrowserPlay}
        leftIcon={isBrowserPlaying ? PauseCircle : Play}
      >
        Browser voice
      </Button>
      <IconButton
        onClick={isPlaying ? onPause : onPlay}
        size='xxl'
        variant='primary'
        icon={isPlaying ? PauseCircle : Play}
      />
    </Wrapper>
  )
}
