import React from 'react'
import styled from 'styled-components'
import SideMenu from 'src/components/Menus/SideMenu'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const ContentWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.base.white};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ContentBox = styled.div`
  box-sizing: content-box;
  flex: 1;
`

type SidebarLayoutProps = {
  title?: string
  children?: JSX.Element
  menuItems: JSX.Element | JSX.Element[]
  backLink: string
  mainHeader?: boolean
  footer?: JSX.Element
  noBack?: boolean
}

function SidebarLayout({
  title,
  children,
  menuItems,
  backLink,
  mainHeader = false,
  footer,
  noBack = false,
}: SidebarLayoutProps) {
  return (
    <Wrapper>
      <SideMenu
        title={title}
        backLink={backLink}
        noBack={noBack}
        mainHeader={mainHeader}
        footer={footer}
      >
        {menuItems}
      </SideMenu>
      <ContentWrapper>
        <ContentBox>{children}</ContentBox>
      </ContentWrapper>
    </Wrapper>
  )
}

export default SidebarLayout
