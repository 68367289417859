import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { RootState } from './store'

export type Pagination = {
  page: number
  limit?: number
}

export const baseUrl = process.env.REACT_APP_API_URL
console.log('baseUrl ', baseUrl)

const getToken = (state: RootState) => {
  return state.auth.token
}

export const baseQuery = () =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken(getState() as RootState)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  })
