import React from 'react'
import { Box, Button } from '@med-io/neural-ui'
import { useDispatch } from 'react-redux'
import { clearAuth } from 'src/redux/authSlice/authSlice'
import { submissionApi } from 'src/redux/submissionApi/submissionApi'

export function SettingsScreen() {
  const dispatch = useDispatch()

  const onLogOut = () => {
    dispatch(clearAuth())
    dispatch(submissionApi.util.resetApiState())
  }

  return (
    <Box p={1}>
      <Button onClick={onLogOut} size='md' variant='secondaryColor' destructive>
        Log out
      </Button>
    </Box>
  )
}
