import Color from "color";
import { uniqueId } from "lodash";

import { fontFamilies } from "./theme";
import React from "react";

export const rgba = (c: string, a: number) =>
  Color(c)
    .alpha(a)
    .rgb()
    .toString();

export const darken = (c: string, a: number) =>
  Color(c)
    .darken(a)
    .toString();

export const lighten = (c: string, a: number) =>
  Color(c)
    .lighten(a)
    .toString();

export const mix = (c: string, b: string, a: number) =>
  Color(c)
    .mix(Color(b), a)
    .toString();

export const ff = (v: string) => {
  const [font, type = "Regular"] = v.split("|");
  // @ts-ignore
  const { style, weight }: any = fontFamilies[font][type];
  // @ts-ignore
  const fallback: any = fontFamilies[font].fallback || "Arial";

  return {
    fontFamily: `${font}, ${fallback}`,
    fontWeight: weight,
    fontStyle: style,
  };
};

export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type ColorType = RGB | RGBA | HEX;

export const invertColor = (hex: HEX) => {
  let hexValue = hex as string
  if (hexValue.indexOf('#') === 0) {
    hexValue = hexValue.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hexValue.length === 3) {
    hexValue = hexValue[0] + hexValue[0] + hexValue[1] + hexValue[1] + hexValue[2] + hexValue[2];
  }
  if (hexValue.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  let r = (255 - parseInt(hexValue.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hexValue.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hexValue.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

const padZero = (str: string, len?: number) => {
  len = len || 2;
  let zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export const multiline = (str: string): React.ReactNode[] =>
  str.split("\n").map(line => <p key={uniqueId()}>{line}</p>);

export const centerEllipsis = (str: string, maxLength: number = 25) =>
  str?.length > maxLength
    ? `${str.substr(0, Math.floor(maxLength / 2))}...${str.substr(Math.floor(-maxLength / 2))}`
    : str;

// export function getMarketColor({
//                                    marketIndicator,
//                                    isNegative,
//                                }: {
//     marketIndicator: string,
//     isNegative: boolean,
// }) {
//     if (isNegative) {
//         return colors[`marketDown_${marketIndicator}`];
//     }
//
//     return colors[`marketUp_${marketIndicator}`];
// }
